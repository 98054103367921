<template>
  <div>
    <v-card :outlined="outlined">
      <v-card-title>{{ title }}</v-card-title>
      <loader v-if="loading" />
      <vue-apex-charts
        v-else
        :type="type"
        height="350"
        :options="chartOptions"
        :series="series"
      ></vue-apex-charts>
    </v-card>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

import Loader from "@/components/common/Loader";

export default {
  name: "MainChart",
  components: {
    Loader,
    VueApexCharts,
  },
  props: {
    title: {
      type: String,
    },
    type: {
      type: String,
      default: "line",
    },
    series: {
      type: Array,
      default: [],
    },
    chartOptions: {
      type: Object,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
